import React, { useContext, useRef, useState } from 'react';
import { ThumbsDown, ThumbsUp, Info } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { List } from '../core/List';
import { String } from '../core/Utils';
import { ApiDataAccess } from '../infrastructure/ApiDataAccess';
import { CapabilityName } from '../infrastructure/Constants';
import { CapabilitiesContext, SettingsContext } from '../infrastructure/Contexts';
import { HierarchyModeService } from '../services/HierarchyModeService';
import { Popup } from '../core/Popup';
import './ApprovalRequestListView.css'


export const ApprovalRequestListView = () => {

    const approvePopup = useRef(null);
    const rejectPopup = useRef(null);
    const navigate = useNavigate();
    const dataAccess = new ApiDataAccess('/api/approvalprocess');
    const capabilitiesContext = useContext(CapabilitiesContext);
    const notifications = capabilitiesContext.getCapability(CapabilityName.Notification);
    const settings = useContext(SettingsContext);
    const hierarchyModes = settings['HierarchyModes'];

    const [requestToAccept, setRequestToAccept] = useState();
    const [requestToReject, setRequestToReject] = useState();

    var rejectionComment = "";

    const onApproveClick = async (item) => {
        setRequestToAccept(item);
        approvePopup.current.show();
    }

    const approveApprovalRequest = async (form, item) => {
        form.preventDefault();
        try {
            await dataAccess.patch(`/approve/${item.key}`);
            notifications.add("info", "Approve request succeeded", "Request approved");
            navigate(0);
        }
        catch (e) {
            if (String.IsJson(e))
                notifications.add("error", "Approve request failed", JSON.parse(e).detail);
            else
                notifications.add("error", "Approve request failed", "There was an error trying to approve the request. Please try again");
        }
    }

    const onRejectClick = async (item) => {
        setRequestToReject(item);
        rejectPopup.current.show();
    }

    const rejectApprovalRequest = async (form, item) => {
        form.preventDefault();
        try {
            await dataAccess.patch(`/reject/${item.key}`, rejectionComment);
            notifications.add("info", "Reject request succeeded", "Request rejected");
            console.log(`${item.key} rejected`);
            navigate(0);
        }
        catch (e) {
            console.log(e);
            if (String.IsJson(e))
                notifications.add("error", "Reject request failed", JSON.parse(e).detail);
            else
                notifications.add("error", "Reject request failed", "There was an error trying to reject approval request. Please try again.");
        }
    }

    return (
        <div>
            <h1>Approval Request</h1>
            <List
                controller='api/approvalprocess'
                action='/'
                headers={[
                    { column: 'mode', title: 'Mode', sortable: true },
                    { column: 'createDate', title: 'Date request', sortable: true },
                    { column: 'requesterName', title: 'Requester', sortable: true },
                    { column: 'discountPercentage', title: 'Discount Percentage', sortable: true },
                    { column: 'profitMarginPercentage', title: 'Profit Margin Percentage', sortable: true },
                    { column: 'currentOwnerName', title: 'Assigner', sortable: true },
                    { column: 'approvalRequestType', title: 'Type', sortable: true },
                    { column: 'icons', title: '', sortable: false }
                ]}

                onRenderRow={(column, item, header) => {
                    switch (column) {
                        case 'icons':
                            return (
                                <span style={{ float: 'right' }}>
                                    {item.isApprovable && <span title="Reject"><ThumbsDown className="table-icon" onClick={() => onRejectClick(item)}></ThumbsDown></span>}
                                    {item.isApprovable && <span title="Approve"><ThumbsUp className="table-icon" onClick={() => onApproveClick(item)}></ThumbsUp></span>}
                                    <span title="Info"><Info className="table-icon" onClick={() => window.open(item.detailPageUrl, "_blank")}></Info></span>
                                </span>)
                        case 'mode':
                            return (<span>{HierarchyModeService.GetModeName(hierarchyModes, item[column])}</span>)
                        default:
                            if (item[column] != null && item[column].toString().length > 0) {
                                return (<span>{item[column].toString()}</span>);
                            }
                            return <span>&nbsp;</span>;
                    }
                }}
            />
            <Popup ref={approvePopup}>
                <div className='contentSection'>
                    Are you sure you want to accept this request having discount {requestToAccept?.discountPercentage}% and profit margin {requestToAccept?.profitMarginPercentage}% ?
                </div>
                <div className='footerSection'>
                    <button className='btn btn-secondary btn-modal' onClick={(form) => approveApprovalRequest(form, requestToAccept)}> Yes </button>
                    <button className='btn btn-primary btn-modal' onClick={() => { approvePopup.current.close(); }}> No </button>
                </div>
            </Popup>

            <Popup ref={rejectPopup}>
                <div className='contentSection'>
                    Are you sure you want to reject this request having discount {requestToReject?.discountPercentage}% and profit margin {requestToReject?.profitMarginPercentage}% ?
                    <div className="reject-popup-comment-container">
                        <label>
                            Comment
                        </label>
                        <textarea type="text" maxLength="100" onChange={e => rejectionComment = e.target.value} />
                    </div>
                </div>

                <div className='footerSection'>
                    <button className='btn btn-secondary btn-modal' onClick={(form) => rejectApprovalRequest(form, requestToReject)}> Yes </button>
                    <button className='btn btn-primary btn-modal' onClick={() => { rejectPopup.current.close(); }}> No </button>
                </div>
            </Popup>
        </div>
    );
}