import React, { useContext, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { Card } from '../core/Card';
import { String } from '../core/Utils';
import { ApiDataAccess } from '../infrastructure/ApiDataAccess';
import { CapabilityName } from '../infrastructure/Constants';
import { CapabilitiesContext } from '../infrastructure/Contexts';
import { useNavigate, useLocation } from 'react-router-dom';

export const CreateEmployeeHierarchyItem = () => {

    const [loaded, setLoaded] = useState(false);
    const [employeeList, setEmployeeList] = useState({});
    const [plantApiEmployeeList, setPlantApiEmployeeList] = useState({});
    const [discountLevelList, setDiscountLevelList] = useState({});
    const [employeeEmail, setEmployeeEmail] = useState();
    const [employeePlant, setEmployeePlant] = useState();

    const capabilitiesContext = useContext(CapabilitiesContext);
    const notifications = capabilitiesContext.getCapability(CapabilityName.Notification);
    const loader = capabilitiesContext.getCapability(CapabilityName.Loader);
    const navigate = useNavigate();
    const location = useLocation();
    const dataAccess = new ApiDataAccess('/api/employee-hierarchy-item');
    const discountLevelDataAccess = new ApiDataAccess('/api/discount-level');

    const hierarchyMode = location.state?.mode;

    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: "onChange"
    });

    useEffect(() => {
        const load = async () => {
            loader.show();
            var calls = [
                discountLevelDataAccess.get("").catch(() => { }),                                                                      // load discount levels
                dataAccess.get(`?pageSize=5000&sort.columnName=name&sort.desc=false&filter.mode=${hierarchyMode.id}`).catch(() => { }),   // load employees from approval app
                dataAccess.get("/plant").catch(() => { })                                                                              // load employees from plant api
            ];

            Promise.all(calls)
                .then((values) => {
                    console.log(values);
                    setDiscountLevelList(values[0] ?? []);
                    setEmployeeList(values[1] ? values[1].items : []);
                    setPlantApiEmployeeList(values[2] ? sortEmployeeList(values[2]) : []);
                    setLoaded(true);
                    loader.hide();
                });
        }

        if (!location.state)
            goBack();
        else
            load();
    }, []);

    function sortEmployeeList(list) {
        return list.sort((a, b) => {
            if (a.firstName > b.firstName)
                return 1;
            if (a.firstName < b.firstName)
                return -1;
            if (a.lastName > b.lastName)
                return 1;
            else
                return -1;
        });
    }

    const onSubmit = async data => {
        data.delegateId = data.delegateId.length == 0 ? null : data.delegateId;
        data.managerId = data.managerId.length == 0 ? null : data.managerId;
        data.mode = hierarchyMode.id;
        console.log("submitted", data);

        try {
            var response = await dataAccess.post("/", data);

            if (Number.isInteger(response)) {
                notifications.add("info", "Create Employee hierarchy item succeeded", "Employee hierarchy item created" );
                goBack();
            }
            else {
                notifications.add("error", "Create Employee hierarchy item failed", "There was an error trying to create the Employee hierarchy item. Please try your request again.");
            }
        }
        catch (e) {
            console.log(e);
            if (String.IsJson(e)) {
                var errorObject = JSON.parse(e);
                switch (errorObject.status) {
                    case 400:
                        notifications.add("error", "Create Employee hierarchy item failed", "Wrong data");
                        break;
                    case 409:
                        notifications.add("error", "Create Employee hierarchy item failed", "Another item with the same name is already present in the system.");
                        break;
                    default:
                        notifications.add("error", "Create Employee hierarchy item failed", errorObject.detail);
                }
            }
            else
                notifications.add("error", "Create Employee hierarchy item failed", "There was an error trying to edit the Employee hierarchy item. Please try your request again.");
        }
    }

    const onChange = data => {
        if (data.target.id == "employeeId") {
            var employeeId = data.target.value;
            var employee = plantApiEmployeeList.find((item) => item.employeeId == employeeId)
            setEmployeeEmail(employee.email);
            setEmployeePlant(employee.plantName);
        }
    }

    const goBack = () => {
        navigate('/employee-hierarchy-item', { state: { mode: hierarchyMode } });
    }

    return (
        loaded && <div>
            <Card title="Create new Employee hierarchy item">
                <form onSubmit={handleSubmit(onSubmit)} onChange={(e) => onChange(e, plantApiEmployeeList)}>
                    <div className="field-container">
                        <label htmlFor="hierarcyMode">Mode</label>
                        <input id="hierarchyMode" className={"control textbox"} type="text" value={hierarchyMode.name} readOnly></input>
                    </div>
                    <div className="field-container">
                        <label htmlFor="employee">Employee</label>
                        <select id="employeeId"
                            className={errors.employeeId ? "control dropdown error" : "control dropdown"}
                            {
                            ...register("employeeId", {
                                required: "employee is required"
                            })}>
                            <option value="">Please select an employee...</option>
                            {plantApiEmployeeList.map(x =>
                                <option value={x.employeeId} key={x.employeeId}>{x.firstName + " " + x.lastName}</option>
                            )}
                        </select>
                        {errors.employeeId && <span className="color-error">{errors.employeeId.message}</span>}
                    </div>
                    <div className="field-container">
                        <label htmlFor="employeeEmail">Email</label>
                        <input id="employeeEmail" className={"control textbox"} type="text" value={employeeEmail} readOnly></input>
                    </div>
                    <div className="field-container">
                        <label htmlFor="employeePlant">Plant</label>
                        <input id="employeePlant" className={"control textbox"} type="text" value={employeePlant} readOnly></input>
                    </div>
                    <div className="field-container">
                        <label htmlFor="discountlevel">Discount level</label>
                        <select name="discountlevelId"
                            className={errors.discountlevelId ? "control dropdown error" : "control dropdown"}
                            {
                            ...register("discountlevelId", {
                                required: "Discount level is required"
                            })}>
                            <option value="">Please select a discount level...</option>
                            {discountLevelList.map(x =>
                                <option value={x.id} key={x.id}>{x.name}</option>
                            )}
                        </select>
                        {errors.discountlevelId && <span className="color-error">{errors.discountlevelId.message}</span>}
                    </div>
                    <div className="field-container">
                        <label htmlFor="manager">Manager</label>
                        <select id="managerId"
                            className={errors.managerId ? "control dropdown error" : "control dropdown"}
                            {
                            ...register("managerId", {})
                            }>
                            <option value="">Please select a manager...</option>
                            {employeeList.map(x =>
                                <option value={x.id} key={x.id}>{x.name}</option>
                            )}
                        </select>
                        {errors.managerId && <span className="color-error">{errors.managerId.message}</span>}
                    </div>
                    <div className="field-container">
                        <label htmlFor="delegate">Delegate</label>
                        <select id="delegateId"
                            className={errors.delegateId ? "control dropdown error" : "control dropdown"}
                            {
                            ...register("delegateId", {})
                            }>
                            <option value="">Please select a delegate...</option>
                            {employeeList.map(x =>
                                <option value={x.id} key={x.id}>{x.name}</option>
                            )}
                        </select>
                        {errors.delegateId && <span className="color-error">{errors.delegateId.message}</span>}
                    </div>
                    <input type="submit" className="btn btn-primary" value="Create" />
                </form>
            </Card>
        </div>
    )
}