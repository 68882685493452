import { React } from "react";
import { Rnd } from "react-rnd";
import { ReactFlowProvider } from "reactflow";
import { Diagram } from "./Diagram";
import { Legend } from "./Legend"
import "./Diagram.css"

export const DiagramContainer = (props) => {
    // Diagram settings
    const size = 7;
    const X_SPACE = 200;
    const Y_SPACE = 100;
    const { innerWidth, innerHeight, scrollY } = window;
    const width = innerWidth * (size / 10);
    const height = innerHeight * (size / 10);
    const edgeStyle =
    {
        type: "smoothstep",
        enabled:
        {
            markerEnd:
            {
                type: "arrowclosed",
                color: "black"
            },
            style:
            {
                stroke: "black"
            }
        },
        disabled:
        {
            markerEnd:
            {
                type: "arrowclosed",
                color: "red"
            },
            style:
            {
                stroke: "red"
            }
        }
    };

    const disabledNodeStyle =
    {
        "borderColor": "red"
    };

    props.data.edges.forEach((edge) => {
        edge.type = edgeStyle.type;
        edge.markerEnd = edge.enabled ? edgeStyle.enabled.markerEnd : edgeStyle.disabled.markerEnd;
        edge.style = edge.enabled ? edgeStyle.enabled.style : edgeStyle.disabled.style;
    })
    props.data.nodes.forEach((node) => {
        node.position.x = node.position.x * X_SPACE;
        node.position.y = node.position.y * Y_SPACE;
        if (!node.enabled)
            node.style = disabledNodeStyle;
    })

    return (
        <Rnd
            className="diagram-container"
            default={{ x: 50, y: 50 + scrollY, width: width, height: height }}
            disableDragging={false}>
            <button className="x-button" onTouchStart={() => { props.onCloseButton() }} onClick={() => { props.onCloseButton() }}>X</button>
            <ReactFlowProvider>
                <Diagram edges={props.data.edges} nodes={props.data.nodes} width='100%' height='90%' /*onNodeClick={props.onNodeClick}*/></Diagram>
            </ReactFlowProvider>
            <Legend />
        </Rnd>
    );
};