import React from "react";
import { ReactFlow, Controls } from "reactflow";
import 'reactflow/dist/style.css';
import MainNode from "./DiagramMainNode";

export const Diagram = ({ nodes, edges, width, height }) => {

    const rfStyle = {
        backgroundColor: 'white'
    };

    const nodeTypes = {
        main: MainNode
    };

    return (
        <div style={{ height, width }}>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                nodesConnectable={false}
                nodesDraggable={false}
                elementsSelectable={false}
                //onNodeClick={onNodeClick}
                panOnScroll={false}
                panOnDrag={true}
                zoomOnScroll={true}
                nodeTypes={nodeTypes}
                fitView
                style={rfStyle}
            >
            </ReactFlow>
            <Controls showInteractive={ false } />
        </div>
    );
};