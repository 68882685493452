import React, { useContext, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { Card } from '../core/Card';
import { String } from '../core/Utils';
import { ApiDataAccess } from '../infrastructure/ApiDataAccess';
import { CapabilityName } from '../infrastructure/Constants';
import { CapabilitiesContext } from '../infrastructure/Contexts';
import { useNavigate, useLocation } from 'react-router-dom';

export const EditEmployeeHierarchyItem = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const capabilitiesContext = useContext(CapabilitiesContext);
    const notifications = capabilitiesContext.getCapability(CapabilityName.Notification);
    const dataAccess = new ApiDataAccess('/api/employee-hierarchy-item');
    const discountLevelDataAccess = new ApiDataAccess('/api/discount-level');  

    const [formValue, setFormValue] = useState({ discountlevelId: null, managerId: null, delegateId: null });
    const [loaded, setLoaded] = useState(false);
    const [employeeList, setEmployeeList] = useState({});
    const [discountLevelList, setDiscountLevelList] = useState({});

    const user = location.state.user;
    const hierarchyMode = location.state?.mode;

    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: "onChange"
    });

    useEffect(() => {
        const load = async () => {

            var calls = [
                discountLevelDataAccess.get("").catch(() => { }),   // discount levels
                dataAccess.get(`?pageSize=5000&sort.columnName=name&sort.desc=false&filter.mode=${hierarchyMode.id}`).catch(() => { }),                // employees from approval app
            ];

            Promise.all(calls)
                .then((values) => {
                    setDiscountLevelList(values[0] ?? []);
                    setEmployeeList(values[1] ? values[1].items : []);
                    setLoaded(true);
                });
        }

        if (!location.state)
            goBack();
        else
            load();
    }, []);

    // get user data by employee ID from AD and update user info (useful when a user changes surname/email)
    const updateContactInfoFromAD = async () => {
        try {
            await dataAccess.put(`/name-address/${user.id}`);
            notifications.add("info", "Update employee hierarchy item address succeeded", "Employee hierarchy item address edited");
            goBack();
        }
        catch (e) {
            console.log(e);
            notifications.add("error", "Update employee hierarchy item address failed", "There was an error trying to edit the employee hierarchy item. Please try your request again.");
        }
    }

    const onSubmit = async data => {
        data.delegateId = data.delegateId.length == 0 ? null : data.delegateId;
        data.managerId = data.managerId.length == 0 ? null : data.managerId;
        console.log("submitted", data);

        try {
            await dataAccess.put(`/${user.id}`, data);
            notifications.add("info", "Edit employee hierarchy item succeeded", "Employee hierarchy item edited");
            goBack();
        }
        catch (e) {
            console.log(e);
            if (String.IsJson(e)) {
                var errorObject = JSON.parse(e);
                switch (errorObject.status) {
                    case 400:
                        notifications.add("error", "Edit employee hierarchy item failed", "Wrong data");
                        break;
                    case 409:
                        notifications.add("error", "Edit employee hierarchy item failed", "Another item with the same name is already present in the system.");
                        break;
                    default:
                        notifications.add("error", "Edit employee hierarchy item failed", errorObject.detail);
                }
            }
            else
                notifications.add("error", "Edit employee hierarchy item failed", "There was an error trying to edit the employee hierarchy item. Please try your request again.");
        }
    }

    const handleChange = event => {
        const value = event.target.value;
        let update = formValue;
        update[event.target.id] = value;
        setFormValue(update);
    }

    const goBack = () => {
        navigate('/employee-hierarchy-item', { state: { mode: hierarchyMode } });
    }

    return (
        loaded && <div>
            <Card title="Edit employee hierarchy item">
                <form id="edit-employee-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="field-container">
                        <label htmlFor="hierarcyMode">Mode</label>
                        <input id="hierarchyMode" className={"control textbox"} type="text" value={hierarchyMode.name} readOnly></input>
                    </div>
                    <div className="field-container">
                        <label htmlFor="name">Name</label>
                        <input id="name" className={"control textbox"} type="text" value={user.name} readOnly></input>
                    </div>
                    <div className="field-container">
                        <label htmlFor="email">Email</label>
                        <input id="email" className={"control textbox"} type="text" value={user.email} readOnly></input>
                    </div>
                    <div className="field-container">
                        <label htmlFor="discountLevel">Discount level</label>
                        <select name="discountLevelId" onChange={handleChange} defaultValue={user.discountLevelId}
                            className={errors.discountlevelId ? "control dropdown error" : "control dropdown"}
                            {
                            ...register("discountLevelId", {
                                required: "discount level is required"
                            })
                            }>
                            <option value="">Please select a discount level...</option>
                            {discountLevelList.map(x =>
                                <option value={x.id} key={x.id}>{x.name}</option>
                            )}
                        </select>
                        {errors.discountLevelId && <span className="color-error">{errors.discountLevelId.message}</span>}
                    </div>
                    <div className="field-container">
                        <label htmlFor="manager">Manager</label>
                        <select name="managerId" onChange={handleChange} defaultValue={user.managerId}
                            className={errors.managerId ? "control dropdown error" : "control dropdown"}
                            {
                            ...register("managerId", {})
                            }>
                            <option value="">Please select a manager...</option>
                            {employeeList.map(x =>
                                <option value={x.id} key={x.id}>{x.name}</option>
                            )}
                        </select>
                        {errors.managerId && <span className="color-error">{errors.managerId.message}</span>}
                    </div>
                    <div className="field-container">
                        <label htmlFor="delegate">Delegate</label>
                        <select name="delegateId" onChange={handleChange} defaultValue={user.delegateId}
                            className={errors.delegateId ? "control dropdown error" : "control dropdown"}
                            {
                            ...register("delegateId", {})
                            }>
                            <option value="">Please select a delegate...</option>
                            {employeeList.map(x =>
                                <option value={x.id} key={x.id}>{x.name}</option>
                            )}
                        </select>
                        {errors.delegateId && <span className="color-error">{errors.delegateId.message}</span>}
                    </div>
                    <input type="submit" className="btn btn-primary" value="Save" />
                    <input type="button" className="btn btn-secondary" value="Update Contact Information from AD" onClick={updateContactInfoFromAD} />
                </form>
            </Card>
        </div>
    )
}