import React, { useContext,  useRef, useState } from 'react';
import { Trash, PlusSquare, Edit2 } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { List } from '../core/List';
import { String } from '../core/Utils';
import { Popup } from '../core/Popup';
import { ApiDataAccess } from '../infrastructure/ApiDataAccess';
import { CapabilityName } from '../infrastructure/Constants';
import { CapabilitiesContext, UserContext } from '../infrastructure/Contexts';
import { Role } from '../infrastructure/Role';


export const DiscountLevelListView = () => {

    const popup = useRef(null);
    const navigate = useNavigate();
    const dataAccess = new ApiDataAccess('/api/discount-level');
    const capabilitiesContext = useContext(CapabilitiesContext);
    const notifications = capabilitiesContext.getCapability(CapabilityName.Notification);
    const userContext = useContext(UserContext);
    const userRole = Role.getValueFromString(userContext.role);
    const adminActionRole = Role.Professional;

    const [discountLevelToDelete, setDiscountLevelToDelete] = useState();

    const onDeleteClick = (discountLevel) => {
        setDiscountLevelToDelete(discountLevel)
        popup.current.show();
    }

    const onDeleteDiscountLevel = async (form, item) => {
        form.preventDefault();
        try {
            await dataAccess.delete(`/${item.id}`);
            notifications.add("info", "Delete discount level succeeded", "Discount level " + item.name + " deleted.");
            navigate(0);
        }
        catch (e) {
            if(String.IsJson(e))
                notifications.add("error", "Delete discount level failed", JSON.parse(e).detail);
            else
                notifications.add("error", "Delete discount level failed", "There was an error trying to delete the discount level. Please try your request again.");
        }
    }

    return (
        <div>
            <h1>Discount Level</h1>
            { userRole >= adminActionRole && <button className='btn btn-primary' onClick={() => { navigate('/discount-level/new') }}><PlusSquare className='icon' /> Create New</button> }
            <List
                controller='api/discount-level'
                action='/'
                headers={[
                    { column: 'name', title: 'Name', sortable: true, width: 5 },
                    { column: 'unitPricePercentage', title: 'Unit Price Percentage', sortable: true, width: 3 },
                    { column: 'marginPercentage', title: 'Margin Percentage', sortable: true, width: 3 },
                    userRole >= adminActionRole && { column: 'trash', title: '', sortable: false, className: 'icon' },
                    userRole >= adminActionRole && { column: 'edit', title: '', sortable: false, className: 'icon' }                    
                ]}
                onRenderRow={(column, item, header) => {
                    switch (column) {
                        case 'edit':
                            return (<span title="Edit"><Edit2 className="pencil-icon" onClick={() => navigate('/discount-level/edit', { state: { item: item } })}></Edit2></span>)
                        case 'trash':
                            return (<span title="Delete"><Trash className="delete-trash-icon" onClick={() => onDeleteClick(item)}></Trash></span>)
                        default:
                            if (item[column] != null) {
                                return (<span>{item[column]}</span>);
                            }
                            return <span>&nbsp;</span>;
                    }
                }}
            />
            <Popup ref={popup}>
                <div className='contentSection'>
                    Are you sure you want to delete discount level {discountLevelToDelete?.name} ?
                </div>

                <div className='footerSection'>
                    <button className='btn btn-secondary btn-modal' onClick={(form) => onDeleteDiscountLevel(form, discountLevelToDelete)}> Yes </button>
                    <button className='btn btn-primary btn-modal' onClick={() => { popup.current.close(); }}> No </button>
                </div>
            </Popup>
        </div>
    ); 
}