import React, { memo } from "react";
import { Handle } from 'reactflow';

export default memo((userInfo) => {

    return (
        <>
            <Handle
                type="source"
                position="top"
                id="manager"
            />
            <Handle
                type="source"
                position="right"
                id="delegate"
            />
            <div>
                <label htmlFor="text">{ userInfo.data.label }</label>
            </div>
            <Handle
                type="target"
                position="bottom"
            />
        </>
    );
});