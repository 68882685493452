import React, { useState, forwardRef, useImperativeHandle } from 'react';

import './Popup.css';


export const Popup = forwardRef(({ children, className, closeOnClickAway, onClose }, ref) => {

    const [visible, setVisible] = useState(false);

    useImperativeHandle(ref, () => ({

        show() {            
            setVisible(true);
        },

        close() {
            setVisible(false);

            if (onClose) {
                setTimeout(() => {
                    onClose();
                }, 100);
            }
        }

    }));

    const closeModal = () => {
        setVisible(false);

        if (onClose) {
            setTimeout(() => {
                onClose();
            }, 100);
        }
    }

    return (
        <div>
            {visible &&
                <div>
                    <div className={closeOnClickAway ? 'backSplash clickable' : 'backSplash'} onClick={() => { if (closeOnClickAway === true) { closeModal() } }} />
                    <div className={className ? `popup noscrollbar ${className}` : 'popup noscrollbar'}>
                        {children}
                    </div>
                </div>
            }
        </div>
        )

});