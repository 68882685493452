import React, { useContext, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { Card } from '../core/Card';
import { ApiDataAccess } from '../infrastructure/ApiDataAccess';
import { CapabilityName } from '../infrastructure/Constants';
import { CapabilitiesContext } from '../infrastructure/Contexts';
import { useNavigate } from 'react-router-dom';
import { String } from '../core/Utils';

export const CreateDiscountLevel = () => {

    const capabilitiesContext = useContext(CapabilitiesContext);
    const notifications = capabilitiesContext.getCapability(CapabilityName.Notification);
    const dataAccess = new ApiDataAccess('/api/discount-level');
    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const onSubmit = async data => {
        console.log("submitted", data);

        try {
            var response = await dataAccess.post("/", data);

            if (Number.isInteger(response)) {
                notifications.add("info", "Create discount level succeeded", "Discount level created");
                navigate("/discount-level");
            }
            else {
                notifications.add("error", "Create discount level failed", "There was an error trying to create the discount level. Please try your request again.");
            }
        }
        catch (e) {
            console.log(e);
            if (String.IsJson(e)) {
                var errorObject = JSON.parse(e);
                switch (errorObject.status) {
                    case 400:
                        notifications.add("error", "Edit discount level failed", "Percentage must be a valid number");
                        break;
                    case 409:
                        notifications.add("error", "Edit discount level failed", "Another item with the same name is already present in the system.");
                        break;
                    default:
                        notifications.add("error", "Edit discount level failed", "There was an error trying to edit the discount level. Please try your request again.");
                }
            }
            else
                notifications.add("error", "Edit discount level failed", "There was an error trying to edit the discount level. Please try your request again.");
        }
    }

    return (
         <div >
            <Card title="Create new discount level">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="field-container">
                        <label htmlFor="name">Name</label>
                        <input id="name" className={"control textbox"} type="text" placeholder="name"
                            {
                            ...register("name", {
                                required: "Name is required",
                                maxLength: 255
                            })} />
                        {errors.name && <span className="color-error">{errors.name.message}</span>}
                    </div>
                    <div className="field-container">
                        <label htmlFor="unit-price-percentage">Unit Price Percentage</label>
                        <input id="unit-price-percentage" className={"control textbox"} type="text" placeholder="unit price percentage"
                            {
                        ...register("unitPricePercentage", {
                                required: "Unit price percentage is required",
                                pattern: {
                                    value: /^(100|\d{0,2})(\.\d{1,2})?$/,
                                    message: "Please enter a valid number"
                                }
                            })} />
                        {errors.unitPricePercentage && <span className="color-error">{errors.unitPricePercentage.message}</span>}
                    </div>
                    <div className="field-container">
                        <label htmlFor="margin-percentage">Margin Percentage</label>
                        <input id="margin-percentage" className={"control textbox"} type="text" placeholder="margin percentage"
                            {
                            ...register("marginPercentage", {
                                required: "Margin percentage is required",
                                pattern: {
                                    value: /^(100|\d{0,2})(\.\d{1,2})?$/,
                                    message: "Please enter a valid number"
                                }
                            })} />
                        {errors.marginPercentage && <span className="color-error">{errors.marginPercentage.message}</span>}
                    </div>
                    <input type="submit" className="btn btn-primary" value="Create" />
                </form>
            </Card>
        </div>
    )
}