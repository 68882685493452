import React, { Component, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Logger } from "./infrastructure/Logger";
import { Configuration } from "./infrastructure/Configuration";
import { PushCommandClient } from "./infrastructure/NotificationSubscribers";
import { CapabilityName } from "./infrastructure/Constants";
import { Role } from './infrastructure/Role';
import { CapabilityRegistry, Capabilities } from "./infrastructure/Capabilities";
import { BackSplashComponent } from "./capabilities/BackSplashComponent";
import { LoaderComponent } from "./capabilities/LoaderComponent";
import { ModalComponent } from "./capabilities/ModalComponent";
import { NotificationComponent } from "./capabilities/NotificationComponent";
import { CapabilitiesContext, SettingsContext, UserContext } from "./infrastructure/Contexts";
import { Home } from "./pages/Home";
import { DiscountLevelListView } from './pages/DiscountLevelListView';
import { CreateDiscountLevel } from './pages/CreateDiscountLevel';
import { EditDiscountLevel } from './pages/EditDiscountLevel';
import { EmployeeHierarchyItemListView } from './pages/EmployeeHierarchyItemListView';
import { CreateEmployeeHierarchyItem } from './pages/CreateEmployeeHierarchyItem';
import { EditEmployeeHierarchyItem } from './pages/EditEmployeeHierarchyItem';
import { ApprovalRequestListView } from './pages/ApprovalRequestListView'
import { UnhandledApprovalRequestListView } from './pages/UnhandledApprovalRequestListView';

import "./order.css";
import "./global.css";
import "./custom.css";

//export default class App extends Component {
//  static displayName = App.name;

//  render() {
//    return (
//      <Layout>
//        <Routes>
//          {AppRoutes.map((route, index) => {
//            const { element, ...rest } = route;
//            return <Route key={index} {...rest} element={element} />;
//          })}
//        </Routes>
//      </Layout>
//    );
//  }
//}

export default function App() {
    const displayName = App.name;
    const capabilities = new CapabilityRegistry();

    const [config, setConfig] = useState(null);
    const [userRole, setUserRole] = useState(null);
    const adminActionRole = Role.Professional;

    useEffect(() => {
        console.log("Application initializing");
        async function init() {

            let configuration = new Configuration();

            try {

                var config = await configuration.getConfigAsync();

                if (config) {

                    var notificationClient = new PushCommandClient(config.settings["Notifications:PushWebSocketUri"], config.settings["Security:ClientId"], config.user.sid);
                    var notificationRegistry = notificationClient.getSubscriberRegistry();

                    notificationRegistry.registerHandler(onCommandReceived);

                    var config = {
                        user: config.user,
                        settings: config.settings,
                        capabilities: capabilities,
                        notificationSubscribers: notificationRegistry
                    }
                    setUserRole(Role.getValueFromString(config.user.role));      

                    Logger.writeDebug("config", config);

                    setConfig(config);
                    console.log("Finished");
                }
            }
            catch (ex) {
                console.log("Application initialization has failed. Redirecting to login page.", ex);
                window.location.replace("account/login");
            }
        }
        init();
    }, []);

    const onCommandReceived = (command) => {
    }

    return (
        <div className="order">

            <BackSplashComponent capabilityRegistry={capabilities} id={CapabilityName.BackSplash} />
            <LoaderComponent capabilityRegistry={capabilities} id={CapabilityName.Loader} />
            <ModalComponent capabilityRegistry={capabilities} id={CapabilityName.Modal} />
            <NotificationComponent capabilityRegistry={capabilities} id={CapabilityName.Notification} />

            {
                config &&
                <SettingsContext.Provider value={config.settings}>
                    <UserContext.Provider value={config.user}>
                            <CapabilitiesContext.Provider value={config.capabilities}>
                                <Layout>
                                    <Routes>
                                        <Route exact path="/" element={<Home />} />
                                        <Route exact path='/discount-level' element={<DiscountLevelListView />} />
                                        {userRole >= adminActionRole && <Route exact path='/discount-level/new' element={<CreateDiscountLevel />} /> }
                                        {userRole >= adminActionRole && <Route exact path='/discount-level/edit' element={<EditDiscountLevel />} />}
                                        <Route exact path='/employee-hierarchy-item' element={<EmployeeHierarchyItemListView />} />
                                        {userRole >= adminActionRole && <Route exact path='/employee-hierarchy-item/new' element={<CreateEmployeeHierarchyItem />} />}
                                        {userRole >= adminActionRole && <Route exact path='/employee-hierarchy-item/edit' element={<EditEmployeeHierarchyItem />} />}
                                        <Route exact path='/approvalprocess' element={<ApprovalRequestListView />} />
                                        <Route exact path='/unhandled-requests' element={<UnhandledApprovalRequestListView />} />
                                    </Routes>
                                </Layout>
                        </CapabilitiesContext.Provider>
                    </UserContext.Provider>
                </SettingsContext.Provider>
            }
        </div>
    );
}