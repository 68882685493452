export class Role {

    static Novice = 1;
    static Intermediate = 2;
    static Advanced = 3;
    static Professional = 4;

    static getValueFromString(stringRole) {
        switch (stringRole) {
            case "Novice":
                return this.Novice;
                break;
            case "Intermediate":
                return this.Intermediate;
                break;
            case "Advanced":
                return this.Advanced;
                break;
            case "Professional":
                return this.Professional;
                break;
            default: 
                return this.Novice;
        }
    }
}