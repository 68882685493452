import React, { useContext, useRef, useState } from 'react';
import { Trash, PlusSquare, Edit2, Power, User, Map } from 'react-feather';
import { useNavigate, useLocation } from 'react-router-dom';
import { List } from '../core/List';
import { String } from '../core/Utils';
import { DropdownList } from "../core/DropdownList";
import { Popup } from '../core/Popup';
import { ApiDataAccess } from '../infrastructure/ApiDataAccess';
import { CapabilityName } from '../infrastructure/Constants';
import { CapabilitiesContext, SettingsContext, UserContext } from '../infrastructure/Contexts';
import { Role } from '../infrastructure/Role';
import { DiagramContainer } from "../components/diagram/DiagramContainer";

export const EmployeeHierarchyItemListView = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const popup = useRef(null);
    const capabilitiesContext = useContext(CapabilitiesContext);
    const notifications = capabilitiesContext.getCapability(CapabilityName.Notification);
    const loader = capabilitiesContext.getCapability(CapabilityName.Loader)
    const userContext = useContext(UserContext);
    const settings = useContext(SettingsContext);
    const hierarchyModes = settings['HierarchyModes'];
    const dataAccess = new ApiDataAccess('/api/employee-hierarchy-item');

    const userRole = Role.getValueFromString(userContext.role);
    const adminActionRole = Role.Professional;
    const mode = location.state?.mode;

    const [diagramVisible, setDiagramVisible] = useState(false);
    const [diagramData, setDiagramData] = useState(false);
    const [hierarchyMode, setHierarchyMode] = useState(mode);
    const [userToDelete, setUserToDelete] = useState();

    const onDeleteEmployeeHierarchyItem = async (form, item) => {
        form.preventDefault();
        try {
            await dataAccess.delete(`/${item.id}`);
            notifications.add("info", "Delete employee hierarchy item succeeded", "Employee hierarchy item " + item.name + " deleted.");
            popup.current.close();
            refreshPage();
        }
        catch (e) {
            if (String.IsJson(e))
                notifications.add("error", "Delete employee hierarchy item failed", JSON.parse(e).detail);
            else
                notifications.add("error", "Delete employee hierarchy item failed", "There was an error trying to delete the employee hierarchy item. Please try your request again.");
        }
    }

    const onChangeEmployeeHierarchyItemStatus = async (item) => {
        try {
            await dataAccess.patch(`/status/${item.id}`);
            notifications.add("info", "Change employee hierarchy item status succeeded");
            refreshPage();
        }
        catch (e) {
            console.log(e);
            notifications.add("error", "Change employee hierarchy item status failed", "There was an error trying to change the employee hierarchy item status. Please try your request again.");
        }
    }

    const onChangeDelegateStatus = async (item) => {
        try {
            await dataAccess.patch(`/delegate-status/${item.id}`);
            notifications.add("info", "Change delegate status succeeded");
            refreshPage();
        }
        catch (e) {
            console.log(e);
            notifications.add("error", "Change delegate status failed", "There was an error trying to change the delegate status. Please try your request again.");
        }
    }

    const loadDiagram = async (item) => {
        loader.show();
        setDiagramVisible(false);
        dataAccess.get(`/diagram-data/${item.id}`)
            .then(data => {
                setDiagramData(data);
                setDiagramVisible(true);
            })
            .catch(() => {
                notifications.add("error", "Error on data loading", "There was an error loading data for the employee. Please try your request again.");
            })
            .finally(() => {
                loader.hide();
            });
    }

    const refreshPage = () => {
        navigate('', {
            state: {
                mode: hierarchyMode
            }
        });
    }

    const goToCreateEmployeePage = () => {
        navigate('/employee-hierarchy-item/new', {
            state: {
                mode: hierarchyMode
            }
        });
    }

    const goToEditEmployeePage = (user) => {
        navigate('/employee-hierarchy-item/edit', {
            state: {
                user: user,
                mode: hierarchyMode
            }
        });
    }

    const onHierarchyModeChange = (value) => {
        setHierarchyMode(value);
    }

    const onDeleteClick = (user) => {
        setUserToDelete(user);
        popup.current.show();
    }

    return (
        <div>
            <h1>Employee Hierarchy Item</h1>
            {userRole >= adminActionRole && <button className='btn btn-primary' onClick={goToCreateEmployeePage}><PlusSquare className='icon' /> Create New</button>}
            <div id='mode-selection-container'>
                MODE
                <DropdownList items={hierarchyModes} value={hierarchyMode ? hierarchyMode.id : 1} onChange={(value) => onHierarchyModeChange(value)} hideEmptyOption="true"></DropdownList>
            </div>  
            {hierarchyMode &&
                <List
                    controller="api/employee-hierarchy-item"
                    action=""
                    filter={{ "mode": hierarchyMode.id }}
                    headers={[
                        { column: 'name', title: 'name', sortable: true, filterable: true },
                        { column: 'email', title: 'email', sortable: true, width: 2 },
                        { column: 'enabled', title: 'enabled', sortable: true },
                        { column: 'discountLevelName', title: 'discount level', sortable: true },
                        { column: 'managerName', title: 'manager', sortable: true },
                        { column: 'delegateName', title: 'delegate', sortable: true },
                        { column: 'delegateEnabled', title: 'delegate enabled', sortable: true },
                        { column: 'icons', title: '', sortable: false }
                    ]}

                    onRenderRow={(column, item, header) => {
                        switch (column) {
                            case 'icons':
                                return (
                                    <span style={{ float: 'right' }}>
                                        {userRole >= adminActionRole && <span title="Delete"><Trash className="table-icon" onClick={() => onDeleteClick(item)}></Trash></span>}
                                        {userRole >= adminActionRole && <span title="Edit"><Edit2 className="table-icon" onClick={() => goToEditEmployeePage(item)}></Edit2></span>}
                                        {userRole >= adminActionRole && <span title="Change user status"><Power className="table-icon" onClick={() => onChangeEmployeeHierarchyItemStatus(item)}></Power></span>}
                                        {userRole >= adminActionRole && <span title="Change delegate status"><User className="table-icon" onClick={() => onChangeDelegateStatus(item)}></User></span>}
                                        <span title="Map"><Map className="table-icon" onClick={() => loadDiagram(item)}></Map></span>
                                    </span>)
                            default:
                                if (item[column] != null && item[column].toString().length > 0) {
                                    return (<span>{item[column].toString()}</span>);
                                }
                                return <span>&nbsp;</span>;
                        }
                    }}
                />
            }         
            {
                diagramVisible &&
                <DiagramContainer data={diagramData} onCloseButton={() => setDiagramVisible(false)} /*onNodeClick={onNodeClick}*/></DiagramContainer>
            }
            <Popup ref={popup}>
                <div className='contentSection'>
                    Are you sure you want to delete user {userToDelete?.name} ?
                </div>

                <div className='footerSection'>
                    <button className='btn btn-secondary btn-modal' onClick={(form) => onDeleteEmployeeHierarchyItem(form, userToDelete)}> Yes </button>
                    <button className='btn btn-primary btn-modal' onClick={() => { popup.current.close(); }}> No </button>
                </div>
            </Popup>
        </div>
    );
}