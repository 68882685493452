import React from "react";
import "./Legend.css"

export const Legend = () => {

    const items = [
        {
            type: 'node',
            color: 'black',
            description: 'User enabled'
        },
        {
            type: 'node',
            color: 'red',
            description: 'User disabled'
        },
        {
            type: 'edge',
            color: 'black',
            description: 'Link enabled'
        },
        {
            type: 'edge',
            color: 'red',
            description: 'Link disabled'
        }
    ];

    return (
        <aside>
            <div className="legendContainer">
                {items.map((item) => {
                    return (
                        <span className="legendItem">
                            <span
                                className={item.type}
                                style={{
                                    color: item.type == 'edge' ? item.color : 'black',
                                    borderColor: item.color
                                }} >
                                { item.type == 'node' ? 'Name' : '→' }
                            </span>
                            = {item.description}
                        </span>
                    );
                })}
            </div>
        </aside>
    );
};