import React, { useContext } from 'react';
import { Info } from 'react-feather';
import { List } from '../core/List';
import { SettingsContext } from '../infrastructure/Contexts';
import { HierarchyModeService } from '../services/HierarchyModeService';

export const UnhandledApprovalRequestListView = () => {

    const settings = useContext(SettingsContext);
    const hierarchyModes = settings['HierarchyModes'];

    return (
        <div>
            <h1>Unhandled Approval Request</h1>
            <List
                controller='api/approvalprocess/unassigned'
                action='/'
                headers={[
                    { column: 'mode', title: 'Mode', sortable: true },
                    { column: 'createDate', title: 'Date request', sortable: true },
                    { column: 'requesterName', title: 'Requester', sortable: true },
                    { column: 'discountPercentage', title: 'Discount percentage', sortable: true },
                    { column: 'profitMarginPercentage', title: 'Profit margin percentage', sortable: true },
                    { column: 'reason', title: 'Reason', sortable: true },
                    { column: 'icons', title: '', sortable: false }
                ]}

                onRenderRow={(column, item, header) => {
                    switch (column) {
                        case 'icons':
                            return (
                                <span style={{ float: 'right' }}>
                                    <Info className="table-icon" onClick={() => window.open(item.detailPageUrl, "_blank")}></Info>
                                </span>)
                        case 'reason':
                            return (<span>User not found in the system</span>); 
                        case 'mode':
                            return (<span>{ HierarchyModeService.GetModeName(hierarchyModes, item[column]) }</span>)
                        default:
                            if (item[column] != null && item[column].toString().length > 0) {
                                return (<span>{item[column].toString()}</span>);
                            }
                            return <span>&nbsp;</span>;
                    }
                }}
            />
        </div>
    );
}