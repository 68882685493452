import { Logger } from './Logger';

export class ApiDataAccess {
    constructor(baseUrl, settings = ApiDataAccess.settings) {

        if (!('fetch' in window)) {
            throw Error('Browser does not support fetch API');
        }

        this.props = {
            baseUrl: baseUrl,
            cacheName: 'default-cache',
            settings: settings
        };
    }

    static settings = {
        mode: "cors",                                               // no-cors, cors, *same-origin
        cache: 'default',                                           // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "include",                                     // include, same-origin, *omit
        redirect: "follow",                                         // manual, *follow, error
        referrer: "no-referrer",                                    // no-referrer, *client
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Accept-Language": "*"
        }
    }

    getAbsoluteUrl(url) {
        return this.props.baseUrl + url;
    }

    get(url) {
        var self = this;
        return new Promise((resolve, reject) => {
            var settings = { ...this.props.settings };
            settings.method = "GET";

            var uri = this.getAbsoluteUrl(url);
            Logger.writeInfo("ApiDataAccess.get.request - " + uri, settings);
            fetch(uri, settings)
                .then(response => {
                    self.evaluateResponse(response)
                        .then(data => resolve(data))
                        .catch(ex => reject(ex));
                })
                .catch(ex => {
                    Logger.writeError("ApiDataAccess.get - " + uri, ex);
                    reject(ex);
                });
        });
    }

    post(url, data) {
        var self = this;
        return new Promise((resolve, reject) => {
            var settings = { ...this.props.settings };
            settings.method = "POST";
            settings.body = JSON.stringify(data);


            var uri = this.getAbsoluteUrl(url);
            Logger.writeInfo("ApiDataAccess.post.request - " + uri, settings);

            fetch(uri, settings)
                .then(response => {

                    self.evaluateResponse(response, uri)
                        .then(data => resolve(data))
                        .catch(ex => reject(ex));
                })
                .catch(ex => {
                    Logger.writeError("ApiDataAccess.post - " + uri, ex);
                    reject(ex);
                });
        });
    }


    put(url, data) {
        var self = this;
        return new Promise((resolve, reject) => {
            var settings = { ...this.props.settings };
            settings.method = "PUT";
            settings.body = JSON.stringify(data);

            var uri = this.getAbsoluteUrl(url);
            Logger.writeInfo("ApiDataAccess.put.request - " + uri, settings);
            fetch(uri, settings)
                .then(response => {
                    self.evaluateResponse(response)
                        .then(data => resolve(data))
                        .catch(ex => reject(ex));
                })
                .catch(ex => {
                    Logger.writeError("ApiDataAccess.put - " + uri, ex);
                    reject(ex);
                });
        });
    }

    patch(url, data) {
        var self = this;
        return new Promise((resolve, reject) => {
            var settings = { ...this.props.settings };
            settings.method = "PATCH";
            settings.body = JSON.stringify(data);

            var uri = this.getAbsoluteUrl(url);
            Logger.writeInfo("ApiDataAccess.patch.request - " + uri, settings);
            fetch(uri, settings)
                .then(response => {
                    self.evaluateResponse(response)
                        .then(data => resolve(data))
                        .catch(ex => reject(ex));
                })
                .catch(ex => {
                    Logger.writeError("ApiDataAccess.patch - " + uri, ex);
                    reject(ex);
                });
        });
    }

    delete(url) {
        var self = this;
        return new Promise((resolve, reject) => {
            var settings = { ...this.props.settings };
            settings.method = "DELETE";

            var uri = this.getAbsoluteUrl(url);
            Logger.writeInfo("ApiDataAccess.delete.request - " + uri, settings);
            fetch(uri, settings)
                .then(response => {
                    self.evaluateResponse(response)
                        .then(data => resolve(data))
                        .catch(ex => reject(ex));
                })
                .catch(ex => {
                    Logger.writeError("ApiDataAccess.delete - " + uri, ex);
                    reject(ex);
                });
        });
    }

    evaluateResponse(response) {
        if (response.status === 0 || response.status === 401 || response.status === 403) {
            console.log(`Unauthorized response received. Status code was ${response.status}. Redirecting to ${encodeURIComponent(window.location.pathname)}`);
            window.location.replace('/account/login?returnUrl=' + encodeURIComponent(window.location.pathname));
            window.location.href = '/account/login?returnUrl=' + encodeURIComponent(window.location.pathname);
            return false;
        }
        else {
            return new Promise((resolve, reject) => {
                const contentType = response.headers.get("content-type");

                if (contentType && contentType.indexOf("application/json") !== -1) {
                    response.json()
                        .then(function (json) {
                            Logger.writeDebug("ApiDataAccess.response.json - " + response.url, json);
                            if (response.ok) {
                                resolve(json);
                            }
                            else {
                                reject(json);
                            }
                        })
                        .catch(err => {
                            Logger.writeError("ApiDataAccess.response.json - " + response.url, err);
                            reject(err);
                        });
                }
                else {

                    if (response.status === 204) {
                        if (response.ok) {
                            resolve({hasSuccess: true});
                        }
                        else {
                            reject();
                        }
                    }
                    else {
                        response.text()
                            .then(function (json) {
                                Logger.writeDebug("ApiDataAccess.response.text - " + response.url, json);
                                if (response.ok) {
                                    resolve(json);
                                }
                                else {
                                    reject(json);
                                }
                            })
                            .catch(err => {
                                Logger.writeError("ApiDataAccess.response.text - " + response.url, err);
                                reject(err);
                            });
                    }
                }
            });
        }
    }


}