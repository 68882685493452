import React, { useState, useEffect } from "react";


export const DropdownList = (props) => {
    const items = props.items ? props.items : [];
    const [value, setOriginalValue] = useState(props.value ? props.value : -1);

    useEffect(() => {
        onChange(value)
    },
        [
            //NOTE: Ok to capture any field from the address that is changing
            value
        ]);
   // const intl = useIntl();

    const isInputValid = () => {
        var isValid = value != -1
        if (props.id && props.onValidated) {
            props.onValidated(props.id, isValid);
        }
        return isValid
    };

    const onSelection = (e) => {
        var id = e.target.value;
        onChange(id);
    }

    const onChange = (id) => {
        setOriginalValue(id)
        isInputValid();
        var item = items.find(x => x.id == id);
        if (props.onChange) {
            props.onChange(item);
        }
    }

    return (
        <>
            {items.length < 1 &&
                <select className="control dropdown" disabled={true} onChange={onSelection} value={value}>
                    <option value={-1}></option>
                </select>
            }

            {items && items.length >= 1 &&
                <select className={isInputValid() ? 'control dropdown' : 'control dropdown error'} onChange={onSelection} value={value}>
                    {!props.hideEmptyOption && <option value={-1}>Please Select...</option>}
                    {items.map((item, i) => {
                        return (
                            <option key={i} value={item.id}>{item.name}</option>
                        )
                    })}
                </select>
            }
        </>
    );
}





