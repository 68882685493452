import React, { Component, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiDataAccess } from '../infrastructure/ApiDataAccess';
import { CapabilityName } from '../infrastructure/Constants';
import { CapabilitiesContext } from '../infrastructure/Contexts';

export const Home = (props) => {
    const navigate = useNavigate();
    const capabilitiesContext = useContext(CapabilitiesContext);
    const loader = capabilitiesContext.getCapability(CapabilityName.Loader);
    const approvalDataAccess = new ApiDataAccess('/api/approvalprocess');
    const [unassignedRequestList, setUnassignedRequestList] = useState();

    useEffect(() => {
        const load = async () => {
            loader.show();
            approvalDataAccess.get(`/unassigned`)
                .then(data => {
                    setUnassignedRequestList(data);
                })
                .finally(loader.hide());
        }
        load();
    }, []);

    return (
        <div>
            <button className='btn btn-square btn-tertiary-outline' onClick={() => { navigate('/discount-level') }}>Discount Levels</button>
            <button className='btn btn-square btn-tertiary-outline' onClick={() => { navigate('/employee-hierarchy-item') }}>Employee Hierarchy Items</button>
            <button className='btn btn-square btn-tertiary-outline' onClick={() => { navigate('/approvalprocess') }}>Approval Requests</button>
            {unassignedRequestList && unassignedRequestList.length > 0 && <button className='btn btn-square btn-tertiary-outline btn-red' onClick={() => { navigate('/unhandled-requests') }}>Unhandled Requests</button>}
        </div>
    );
}